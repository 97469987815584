<template v-if="device">
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Ping documents</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1">Ping documents of device.</span>
      </div>
    </div>

    <div class="card-body">
      <!-- begin::Datatable -->
      <datatable
        ref="datatable"
        :url="datatable.url"
        :fields="datatable.fields"
        :filters="datatable.filters"
        :search-fields="datatable.searchFields"
        :on-pagination-data="onPaginationData"
      >
        <!-- begin::Actions -->
        <div slot="actions" slot-scope="props">
          <b-dropdown
            ref="actions-dropdown"
            variant="clean btn-sm btn-icon"
            class="dropdown-inline mr-2"
            right
            lazy
            no-caret
            boundary="window"
            menu-class="dropdown-menu-md"
          >
            <template v-slot:button-content>
              <span class="svg-icon">
                <font-awesome-icon :icon="['fas', 'cog']" />
              </span>
            </template>

            <template v-slot:default>
              <ul class="navi flex-column navi-hover py-2">
                <li class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                  Choose an action:
                </li>

                <li class="navi-separator my-0" />

                <template>
                  <li class="navi-item">
                    <a class="navi-link cursor-pointer" @click.prevent="downloadDocument(props.rowData)">
                      <span class="navi-icon">
                        <i>
                          <font-awesome-icon :icon="['fas', 'file-code']" class="w-15px h-15px" />
                        </i>
                      </span>
                      <span class="navi-text">Download JSON</span>
                    </a>
                  </li>
                </template>
              </ul>
            </template>
          </b-dropdown>
        </div>
        <!-- end::Actions -->
      </datatable>
      <!-- end::Datatable -->

      <!-- begin::Datatable footer -->
      <div class="datatable datatable-default">
        <div class="datatable-pager datatable-paging-loaded">
          <datatable-pagination
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />

          <datatable-pagination-info
            ref="pagination.info"
            @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
          />
        </div>
      </div>
      <!-- end::Datatable footer -->
    </div>
  </div>
</template>

<script>
import { DatatableMixin } from '@vedicium/metronic-vue';
import moment from 'moment-timezone';

import Device from '@/libs/classes/device';

export default {
  mixins: [DatatableMixin],
  props: {
    device: {
      type: Device,
      default: null,
    },
  },
  data () {
    return {
      datatable: {
        url: `${Device.uri}/${this.device._meta.guid}/pings`,

        fields: [{
          name: '_meta.created',
          title: 'Ping time',
          sortField: '_meta.created',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
          formatter (value) {
            return moment.utc(value).format('LLL');
          },
        }, {
          name: 'actions',
          title: 'Actions',
          titleClass: 'w-125px text-right',
          dataClass: 'w-125px text-right',
        }],
        searchFields: [],
      },
    };
  },

  methods: {
    transform (data) {
      const response = { ...data };
      return response;
    },

    async downloadDocument (document = null) {
      if (!document) {
        return;
      }

      this.$refs.datatable.setLoading(true);
      try {
        this.$http.downloadFile(`device_ping.${moment.utc(document._meta.created).format('YYYY-MM-DD_HH-mm')}.${document._meta.guid}.json`, JSON.stringify(document), 'application/json');
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$refs.datatable.setLoading(false);
      }
    },
  },
};
</script>
